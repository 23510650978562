<template>
  <div class="footer">
<span
  style="color: rgb(170, 170, 170);">Copyright © 2024 ClouGence,Inc.<a href="http://www.miit.gov.cn/" target="_blank" style="color: rgb(170, 170, 170);margin-left: 10px ">浙ICP备20007605号-1</a></span>
  </div>
</template>

<script>
export default {
  name: 'CCFooter'
};
</script>

<style lang="less" scoped>
.footer {
  height: 56px;
  line-height: 56px;
  text-align: center;
  color: #888;
}
</style>
