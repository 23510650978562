<template>
  <div class="logo-header">
    <div class="logo-header-container">
      <div class="left">
        <cc-iconfont v-if="includesDM && !includesCC" name="dm" size="28" color="#222222"/>
        <svg v-if="includesCC && !includesDM" class="icon svg-icon" aria-hidden="true" style="font-size:180px;color:#222222">
          <use xlink:href="#icon-cc"></use>
        </svg>
        <img v-if="includesCC && includesDM" src="../assets/logo-rdp.svg" alt="ClouGence RDP">
<!--        <i v-if="includesCC" class="iconfont icon-cc" style="font-size:28px;color:#222222"></i>-->
        <div class="login-type">
          {{ TYPES[type] }}
        </div>
      </div>
      <div class="right">
        <Dropdown @on-click="handleLangChange" transfer trigger="click">
          <a href="javascript:void(0)"  style="width: 100px;margin-right: 10px;">
            {{LANG_I18N[$i18n.locale]}}
            <Icon type="ios-arrow-down"></Icon>
          </a>
          <DropdownMenu slot="list">
            <DropdownItem name="zh-CN" :selected="$i18n.locale==='zh-CN'">
              <div style="text-align: center">中文</div>
            </DropdownItem>
            <DropdownItem name="en-US" :selected="$i18n.locale==='en-US'">
              <div style="text-align: center">English</div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <div class="login" v-if="type!=='login'">
          {{ $t('yi-you-zhang-hao-qu') }}
          <a @click="goLogin">{{ $t('deng-lu') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { LANG_I18N } from '../const';

export default {
  name: 'CCLogoHeader',
  props: {
    type: {
      type: String,
      default: 'login'
    }
  },
  computed: {
    ...mapGetters([
      'includesCC',
      'includesDM'
    ])
  },
  data() {
    return {
      LANG_I18N,
      TYPES: {
        register: this.$t('zhu-ce'),
        login: this.$t('deng-lu'),
        reset: this.$t('zhao-hui-mi-ma')
      }
    };
  },
  methods: {
    async handleLangChange(value) {
      await localStorage.setItem('lang', value);
      this.$router.go(0);
    },
    goLogin() {
      this.$router.push({ name: 'Login' });
    }
  }
};
</script>

<style lang="less" scoped>
.logo-header {
  display: flex;
  align-items: center;
  margin: 0 105px;
  height: 80px;
  justify-content: space-between;
  position: relative;

  .logo-header-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  .login-type {
    color: #555555;
  }

  .left {
    display: flex;
    align-items: center;

    div {
      font-size: 24px;
      font-weight: bold;
      border-left: 1px solid #DADADA;
      padding-left: 20px;
      margin-left: 20px;
    }
  }

  .right {
    display: flex;

    .login {
      font-size: 14px;

      a {
        font-size: 14px;
      }

      span {
        color: @primary-color;
      }
    }
  }
}
</style>
